import useLocalStorageState from "use-local-storage-state";
import { NewUser, PentrackerUser } from "../../domain/user/User";
import { UserListQueryRequest } from "../../network/user/User";
import { useContext, useEffect, useState } from "react";
import { Response } from "../../domain/app/Response";
import UserRepository from "../../data/repository/UserRepository";
import { filter, first, isEqual, remove } from "lodash";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../data/database/db";
import { LocalErrorLogRepository } from "../../data/repository/LocalErrorLogRepository";
import { UserAuthContext } from "../../ui/context/UserContext";
import { DeleteUser } from "../../domain/user";

export function useUserListViewModel() {
  const { appUser } = useContext(UserAuthContext);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [requestParams, setRequestParams] =
    useLocalStorageState<UserListQueryRequest>(
      "user_pagination_map_request_params",
      {
        defaultValue: {
          orderBy: "displayName",
          order: "asc",
        },
      }
    );

  const [userList, setUserList] = useState<PentrackerUser[] | undefined>();

  const listLookup = useLiveQuery(() => db.users.toArray(), [requestParams]);

  /* eslint-disable */
  useEffect(() => {
    // void fetchUserList(requestParams, false);
  }, [listLookup]);
  /* eslint-enable */
  const [searchResults, setSearchResults] = useState<PentrackerUser[] | null>(
    null
  );

  const [createEvent, setCreateEvent] = useState<boolean | null>(null);
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const [deleteEvent, setDeleteEvent] = useState<Array<{
    label: string;
    uid: string;
  }> | null>(null);
  const [fetchListState, setFetchListState] =
    useState<Response<boolean> | null>(null);
  const [createState, setCreateState] = useState<Response<boolean> | null>(
    null
  );
  const [deleteState, setDeleteState] = useState<Response<boolean> | null>(
    null
  );
  const [editEvent, setEditEvent] = useState<PentrackerUser | null>(null);
  const [editState, setEditState] = useState<Response<boolean> | null>(null);
  const [searchState, setSearchState] = useState<Response<boolean> | null>(
    null
  );
  const [searchEvent, setSearchEvent] = useState<boolean | null>(null);
  const [filterUsersEvent, setFilterUsersEvent] = useState<boolean | null>(
    null
  );

  async function fetchUserList(
    params = requestParams,
    forced: boolean = false
  ) {
    if (fetchListState && fetchListState.loading) return;
    setFetchListState(Response.loading());
    try {
      const list = await UserRepository.getUserList(params, forced);
      if (!!list) {
        const currentUserUid = appUser.uid();
        const currentUserInList = first(
          filter(list, (it) => it.uid === currentUserUid)
        );
        if (!!currentUserInList) {
          remove(list, currentUserInList);
          setUserList([currentUserInList, ...list]);
        } else {
          setUserList(list);
        }
      }
      setFetchListState(Response.success(true));
    } catch (e: any) {
      console.log(e);
      const label = "Error al obtener lista de usuarios";
      await LocalErrorLogRepository.registerError(label, e);
      setFetchListState(Response.failure(new Error(label)));
    }
  }

  async function createUser(newUser: NewUser) {
    if (createState && createState.loading) return;
    setCreateState(Response.loading());
    try {
      await UserRepository.createUser(newUser);
      setCreateState(Response.success(true));
    } catch (e: any) {
      const label = `Error al crear usuario id: ${newUser.uid}, nombre: ${newUser.displayName}`;
      await LocalErrorLogRepository.registerError(label, e);
      setCreateState(Response.failure(new Error(label)));
    }
  }

  async function editUser(userData: Partial<NewUser>) {
    if (editState && editState.loading) return;
    setEditState(Response.loading());
    if (!!editEvent) {
      const { displayName, uid } = editEvent;
      try {
        await UserRepository.editUser(uid, userData);
        setEditState(Response.success(true));
        removeUserSelection(uid);
      } catch (e: any) {
        const label = `Error al editar usuario '${displayName}' con id: ${uid}`;
        await LocalErrorLogRepository.registerError(label, e);
        setEditState(Response.failure(new Error(label)));
      }
    } else {
      setEditState(
        Response.failure(
          new Error("Debe seleccionar sólo 1 usuario para eliminar.")
        )
      );
    }
  }

  async function deleteUser(data: DeleteUser) {
    if (deleteState && deleteState.loading) return;
    setDeleteState(Response.loading());
    const uidListToDelete = deleteEvent;
    if (!uidListToDelete || uidListToDelete.length <= 0) {
      setDeleteState(
        Response.failure(
          new Error("No se seleccionaron usuarios para eliminar.")
        )
      );
      return;
    }
    let errorCount = 0;
    const tasks = uidListToDelete.map(async (it) => {
      try {
        await UserRepository.deleteUser({ uid: it.uid, reason: data.reason });
      } catch (e: any) {
        errorCount += 1;
        const label = `Error mientras se eliminaba a usuario con id: '${it.uid}'`;
        await LocalErrorLogRepository.registerError(label, e);
      }
    });
    await Promise.all(tasks);
    deleteSelection();
    if (errorCount === 0) {
      setDeleteState(Response.success(true));
    } else if (errorCount === uidListToDelete.length) {
      setDeleteState(
        Response.failure(
          new Error(
            `Ocurrió un error al eliminar los usuarios seleccionados. ${errorCount} error(es)`
          )
        )
      );
    } else {
      setDeleteState(
        Response.successWithWarning(
          `Algunas operaciones de eliminación de usuarios fallaron.  ${errorCount} error(es)`
        )
      );
    }
  }

  function onFetchStateReceived() {
    setFetchListState(null);
  }

  function requestCreateEvent() {
    setCreateEvent(true);
  }

  function onCreateEventCompleted() {
    setCreateEvent(null);
  }

  function onCreateStateReceived() {
    setCreateState(null);
  }

  async function requestDeleteUser(uidToDelete: Array<string> = selectedUsers) {
    if (uidToDelete.length <= 0)
      throw new Error("No hay usuarios seleccionados para eliminar.");
    const tasks = uidToDelete.map(async (it) => {
      const user = await db.users.get(it);
      if (!!user) {
        return {
          label: user.displayName || user.uid,
          uid: user.uid,
        };
      } else {
        return {
          label: it,
          uid: it,
        };
      }
    });
    const resolvedTask = await Promise.all(tasks);
    setDeleteEvent(resolvedTask);
  }

  function onDeleteEventCompleted() {
    setDeleteEvent(null);
  }

  function onDeleteStateReceived() {
    setDeleteState(null);
  }

  async function requestEditUser(uidToEdit: Array<string> = selectedUsers) {
    if (uidToEdit.length <= 0) {
      setEditState(
        Response.failure(
          new Error("No hay usuarios seleccionados para editar.")
        )
      );
    } else if (uidToEdit.length === 1) {
      const user = await UserRepository.getUser(uidToEdit[0]);
      if (!!user) setEditEvent(user);
      else
        setEditState(
          Response.failure(new Error("El usuario no fue encontrado."))
        );
    } else {
      setEditState(
        Response.failure(new Error("No es posible editar más de un usuario."))
      );
    }
  }

  function onEditEventCompleted() {
    setEditEvent(null);
  }

  function onEditStateReceived() {
    setEditState(null);
  }

  function requestSearchEvent() {
    setSearchEvent(true);
  }

  function onSearchEventCompleted() {
    setSearchEvent(null);
  }

  async function searchUser(keyword: string) {
    setSearchState(Response.loading());
    try {
      const results = await UserRepository.searchUser(keyword);
      setSearchResults(results || []);
      setSearchState(Response.success(true));
    } catch (e: any) {
      const label = `Error al buscar usuario: '${keyword}'`;
      await LocalErrorLogRepository.registerError(label, e);
      setSearchState(Response.failure(new Error(label)));
    }
  }

  function onSearchStateReceived() {
    setSearchState(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRequestRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  function requestFilterUsersEvent() {
    setFilterUsersEvent(true);
  }

  function onFilterUsersEventCompleted() {
    setFilterUsersEvent(null);
  }

  function removeUserSelection(uid: string) {
    const existingIndex = selectedUsers.indexOf(uid);
    if (existingIndex !== -1) {
      setSelectedUsers((oldList) => {
        const copy = [...oldList];
        copy.splice(existingIndex, 1);
        return copy;
      });
    }
  }

  function addUserSelection(uid: string) {
    const existingIndex = selectedUsers.indexOf(uid);
    if (existingIndex === -1) {
      setSelectedUsers((oldList) => {
        const copy = [...oldList];
        copy.push(uid);
        return copy;
      });
    }
  }

  function switchUserSelection(uid: string) {
    const existingIndex = selectedUsers.indexOf(uid);
    if (existingIndex === -1) {
      addUserSelection(uid);
    } else {
      removeUserSelection(uid);
    }
  }

  function switchSelectAll() {
    if (userList && selectedUsers.length === userList.length) {
      setSelectedUsers([]);
    } else if (!!userList) {
      setSelectedUsers(userList.map((it) => it.uid));
    }
  }

  function deleteSelection() {
    setSelectedUsers([]);
  }

  function updateRequestParams({
    orderBy = requestParams.orderBy,
    order = requestParams.order,
  }: Partial<UserListQueryRequest>) {
    const newParams = {
      orderBy,
      order,
    };
    if (!isEqual(newParams, requestParams)) {
      setRequestParams(newParams);
    }
  }

  const loadObserver =
    fetchListState?.loading ||
    createState?.loading ||
    editState?.loading ||
    deleteState?.loading ||
    searchState?.loading;

  return {
    createEvent,
    refreshEvent,
    deleteEvent,
    fetchListState,
    createState,
    deleteState,
    editEvent,
    editState,
    searchState,
    requestParams,
    updateRequestParams,
    fetchUserList,
    onFetchStateReceived,
    requestCreateEvent,
    onCreateEventCompleted,
    createUser,
    onCreateStateReceived,
    requestDeleteUser,
    onDeleteEventCompleted,
    deleteUser,
    onDeleteStateReceived,
    requestEditUser,
    onEditEventCompleted,
    editUser,
    onEditStateReceived,
    requestSearchEvent,
    onSearchEventCompleted,
    searchUser,
    onSearchStateReceived,
    requestRefreshEvent,
    setRefreshEvent,
    onRequestRefreshEventCompleted,
    loadObserver,
    searchEvent,
    filterUsersEvent,
    requestFilterUsersEvent,
    onFilterUsersEventCompleted,
    userList,
    searchResults,
    switchUserSelection,
    switchSelectAll,
    selectedUsers,
    deleteSelection,
  };
}
