import { FC } from "react";
import { FaIcon } from "../../../components/fontawesome/icon";
import {
  faBuilding,
  faCalendar,
  faLocation,
  faPerson,
} from "@fortawesome/free-solid-svg-icons";
import { toRelativeCalendar } from "../../../../lib/date";
import { Link } from "react-router-dom";
import { PathImageLoader } from "../../../components/imageloader/PathImageLoader";
import { UserPatrol } from "../../../../domain/patrol/Patrol";

export const UserPatrolView: FC<{
  patrol: UserPatrol;
}> = ({ patrol }) => {
  const {
    incident,
    location,
    owner,
    ownerPhoto,
    ownerUid,
    submoduleId,
    timestamp,
    unity,
    unityId,
    zone,
  } = patrol;

  return (
    <div
      className={
        "w-full h-full overflow-auto flex flex-row flex-wrap gap-x-4 py-4"
      }
    >
      <p className={"px-2 m-0 text-neutral-900 text-lg uppercase grow"}>
        Usuario: {owner?.displayName || ownerUid}
      </p>
      <div className={"w-full flex flex-col p-2 rounded items-start gap-2"}>
        <div className={"flex flex-col flex-wrap w-fit gap-y-4"}>
          <div className={"flex flex-col gap-2"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>GENERAL</p>
            <div
              className={
                "w-full flex flex-row flex-wrap gap-x-6 gap-y-2 items-center"
              }
            >
              <div>
                <FaIcon icon={faPerson} className={"mr-2 text-neutral-400"} />
                <Link to={`/dashboard/users/${ownerUid}`}>
                  {owner ? owner.displayName : submoduleId}
                </Link>
              </div>

              <div className={"flex flex-row gap-x-2 flex-wrap"}>
                <FaIcon icon={faCalendar} className={"text-neutral-400"} />
                <span className={"font-semibold uppercase"}>Registrado el</span>
                {toRelativeCalendar(timestamp, true)}
              </div>

              <div>
                <FaIcon icon={faBuilding} className={"mr-2 text-neutral-400"} />
                <Link to={`/dashboard/unity/${unityId}`}>
                  {unity ? unity.label : unityId}
                </Link>
              </div>

              <div>
                <FaIcon icon={faLocation} className={"mr-2 text-neutral-400"} />
                <Link
                  to={`/dashboard/unity/${unityId}/zoneView/${submoduleId}`}
                >
                  {zone ? zone.label : submoduleId}
                </Link>
              </div>
            </div>
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              FOTO DE RONDA
            </p>
            <PathImageLoader
              path={ownerPhoto}
              className={"object-cover aspect-square rounded-2xl max-h-96"}
            />
          </div>

          <div className={"flex flex-col gap-y-2 justify-start items-start"}>
            <p className={"text-neutral-400 text-md uppercase grow"}>
              INCIDENCIA
            </p>
            {incident ? (
              <div className={"flex flex-col w-full"}>
                {incident.type}
                {incident.details}
                {incident.media ? (
                  <div className={"w-full overflow-x-auto flex flex-row gap-2"}>
                    {incident.media.map((mediaItem, i) => (
                      <PathImageLoader
                        key={`pitem-${i}`}
                        path={mediaItem}
                        className={
                          "object-cover aspect-square rounded-2xl max-h-96"
                        }
                      />
                    ))}
                  </div>
                ) : (
                  <span className={"italic"}>No se añadieron archivos.</span>
                )}
              </div>
            ) : (
              <span>No se registró.</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
